<template>
  <section class="organism-property-profile">
    <div class="property-card-container">
      <div class="flex justify-space-between align-center">
        <txt size="heading-5">Profile Properti</txt>
        <router-link :to="formProfilePropertyRoute">
          <bg-button>Ubah</bg-button>
        </router-link>
      </div>
      <hr />

      <div v-if="isLoadingPropertyDetail">
        <grid v-for="i in 3" :key="i">
          <grid-item v-for="j in 3" :key="j" :col="4">
            <div class="field-title">
              <skelly width="70px" height="24px" />
            </div>
            <div>
              <skelly width="120px" height="24px" />
            </div>
          </grid-item>
        </grid>
      </div>

      <div v-else>
        <grid>
          <grid-item :col="4">
            <txt size="body-1" class="field-title">ID</txt>
            <txt size="body-2">{{ xPropertyDetail.id }}</txt>
          </grid-item>
          <grid-item :col="4">
            <txt size="body-1" class="field-title">Nama Properti</txt>
            <txt size="body-2">{{ xPropertyDetail.name || '-' }}</txt>
          </grid-item>
          <grid-item :col="4">
            <txt size="body-1" class="field-title">Jenis Produk</txt>
            <txt size="body-2">{{ xPropertyDetail.product || '-' }}</txt>
          </grid-item>
        </grid>
        <grid>
          <grid-item :col="4">
            <txt size="body-1" class="field-title">Tipe</txt>
            <txt size="body-2">{{ xPropertyDetailUnitType || '-' }}</txt>
          </grid-item>
          <grid-item :col="4">
            <txt size="body-1" class="field-title"
              >Syarat Pekerjaan Penyewa</txt
            >
            <txt size="body-2">{{ xPropertyDetailJobs || '-' }}</txt>
          </grid-item>
          <grid-item :col="4">
            <txt size="body-1" class="field-title">Syarat Agama Penyewa</txt>
            <txt size="body-2">{{ xPropertyDetailReligions || '-' }}</txt>
          </grid-item>
        </grid>
        <grid>
          <grid-item :col="4">
            <txt size="body-1" class="field-title">Alamat</txt>
            <txt size="body-2">{{ xPropertyDetail.address || '-' }}</txt>
          </grid-item>
          <grid-item :col="4">
            <txt size="body-1" class="field-title">Peta Lokasi</txt>
            <txt size="body-2"
              ><bg-link
                :href="xPropertyDetail.maps_link"
                variant="medium"
                target="_blank"
                rel="noopener"
                >Lihat Peta Lokasi</bg-link
              ></txt
            >
          </grid-item>
        </grid>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { LOADING_STATE } from '@admin_store/modules/propertyDetail';

import {
  BgText,
  BgGrid,
  BgGridItem,
  BgSkeleton,
  BgLink,
  BgButton,
} from 'bangul-vue';

export default {
  name: 'PropertyProfile',

  components: {
    txt: BgText,
    grid: BgGrid,
    gridItem: BgGridItem,
    skelly: BgSkeleton,
    BgLink,
    BgButton,
  },

  computed: {
    ...mapState('propertyDetail', ['xLoadingState', 'xPropertyDetail']),
    ...mapGetters('propertyDetail', [
      'xPropertyDetailUnitType',
      'xPropertyDetailJobs',
      'xPropertyDetailReligions',
    ]),

    isLoadingPropertyDetail() {
      return this.xLoadingState === LOADING_STATE.FETCHING;
    },

    formProfilePropertyRoute() {
      return {
        name: 'edit.property-profile',
        params: {
          propertyId: this.$route.params.propertyId,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped src="./PropertyProfile.scss"></style>
